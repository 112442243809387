import {Canvas, useLoader, useThree} from '@react-three/fiber';
import React from "react";
import { OrbitControls, Stage, PerspectiveCamera, Loader} from "@react-three/drei";
import { Suspense } from "react";
import Beer from "./models/Beer";
import './App.css';


function App() {
    const ref = React.useRef()
    const cameraRef = React.useRef();


    return (
        <div className="App">
            <Canvas dpr={[1, 2]} style={{ backgroundSize: 'contain', backgroundImage: `url(/bg.jpg)` }}>
                <PerspectiveCamera ref={cameraRef} fov={10} position={[12, 0, 0]} makeDefault />
                <Suspense fallback={null}>
                    <Stage adjustCamera={false} controls={ref} preset="rembrandt" intensity={0.5} shadows={false}>
                        <group position={[0, 2, 0]}>
                            <Beer />
                        </group>
                    </Stage>
                </Suspense>

                <OrbitControls maxDistance={50} minDistance={5} enableZoom={true} enablePan={false} target={[0, 0, 0]} />
            </Canvas>
            <Loader />
        </div>
  );
}

export default App;
