import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import * as THREE from "three";


const audio = new Audio("/music.mp3");

export default function Beer(props) {
    const group = useRef();
    const { nodes, materials, animations } = useGLTF("/vinylfinal.glb");
    const { actions } = useAnimations(animations, group);

    const playAnimation = () => {
           const animation = actions.Rotate;
           if(animation.isRunning()) {
                animation.stop();
                audio.pause();
                audio.currentTime = 0;
           } else {
               animation.reset();
               animation.play();
               audio.play();
               animation.loop = THREE.LoopOnce;
           }

    }

    // React.useEffect(() => {
    //
    //    const animation = actions.Rotate;
    //     console.log("animation: ", animation);
    //    animation.play();
    //
    // }, [actions]);

    return (
        <group onClick={playAnimation} ref={group} {...props} dispose={null}>
            <group name="Scene">
                <group name="Vinyl" position={[0.01, 0, -0.003]}>
                    <mesh
                        name="Cylinder"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cylinder.geometry}
                        material={materials["Material.001"]}
                        position={[-0.01, 0, 0.003]}
                        rotation={[39.25, 0, -Math.PI / 2]}
                        scale={[0.84, 0.004, 0.8]}
                    />
                </group>
            </group>
        </group>
    );
}

useGLTF.preload("/vinylfinal.glb");